import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { getUser, getUsers, deleteUser } from "../../actions/auth";
import {
  Button,
  Card,
  Col,
  Container,
  ButtonToolbar,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

const Users = () => {
  const { auth, isLoading } = useSelector((state) => state.auth);
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUsers(user?.result._id, user?.result.user_role));
  }, []);

  if (isLoading) {
    return (
      <Spinner
        animation="grow"
        variant="danger"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="11">
            <Card className="regular-table-with-color">
              <Card.Header>
                <Card.Title as="h4">Users</Card.Title>
                <p className="card-category">List of active Users</p>
              </Card.Header>
              <Col md="12">
                <Card.Body className="table-responsive p-0">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>
                          <strong>#</strong>
                        </th>
                        <th>
                          <strong>Full Name</strong>
                        </th>
                        <th>
                          <strong>Email</strong>
                        </th>
                        <th>
                          <strong>ID</strong>
                        </th>
                        <th>
                          <strong>Role</strong>
                        </th>
                        <th>
                          <strong>Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {auth?.map((agent, index) => (
                        <tr key={agent?._id}>
                          <td>{index}. </td>
                          <td>{agent?.name}</td>
                          <td>{agent?.email}</td>
                          <td>{agent?._id}</td>
                          <td>{agent?.user_role}</td>
                          <td>
                            <ButtonToolbar>
                              {["top"].map((anchor) => (
                                <OverlayTrigger
                                  key={anchor}
                                  placement={anchor}
                                  overlay={
                                    <Tooltip id={`tooltip-${anchor}`}>
                                      Edit User Info
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(
                                        getUser(
                                          agent?._id,
                                          user?.result?._id,
                                          user?.result.user_role
                                        )
                                      );
                                      history.push(`/user-edit/${agent._id}`);
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                </OverlayTrigger>
                              ))}
                            </ButtonToolbar>

                            {/* Delete User */}
                            <ButtonToolbar>
                              {["right"].map((anchor) => (
                                <OverlayTrigger
                                  key={anchor}
                                  placement={anchor}
                                  overlay={
                                    <Tooltip id={`tooltip-${anchor}`}>
                                      Delete User
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(
                                        deleteUser(
                                          agent._id,
                                          user?.result._id,
                                          user?.result.user_role
                                        )
                                      );
                                      history.push("/admin/users");
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Button>
                                </OverlayTrigger>
                              ))}
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Col style={{ paddingBottom: "15px" }}>
                    <Card.Body>
                      <Col
                        className="font-icon-list"
                        lg="2"
                        md="3"
                        sm="4"
                        xs="6"
                      >
                        <Button
                          className="btn-outline btn-round btn-wd mr-1"
                          variant="danger"
                          onClick={() => history.push("/admin/user-form/")}
                        >
                          {" "}
                          <i
                            className="nc-icon nc-simple-add"
                            style={{ marginRight: "5px" }}
                          >
                            <i className="fas fa-user" />
                          </i>
                          Add Agent
                        </Button>
                      </Col>
                    </Card.Body>
                  </Col>
                </Card.Body>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Users;
