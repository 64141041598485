import React, { useEffect, useState } from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map

// react-bootstrap components
import {
  Button,
  Card,
  Spinner,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { getPosts } from "actions/posts";
import PregnantWomanOutlinedIcon from "@material-ui/icons/PregnantWomanOutlined";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import { getAllPosts, getUserStats } from "../actions/posts";
// import { useLocation } from "react-router";

const Dashboard = () => {
  const { postsData, isLoading, userStats } = useSelector(
    (state) => state.posts
  );
  // const { auth } = useSelector((state) => state.auth);
  // console.log(userStats, "userStats????");
  // console.log(postsData, "postsData");
  const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  // console.log(user.result.user_role)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPosts(user?.result?._id, user?.result?.user_role));
  }, []);

  useEffect(() => {
    dispatch(getUserStats());
  }, [dispatch]);

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const males = postsData.filter((element) => element["callerSex"] == "male");
  const females = postsData.filter(
    (element) => element["callerSex"] == "female"
  );
  const undisclosed = postsData.filter(
    (element) => element["callerSex"] == "undisclosed"
  );

  const central = postsData.filter((element) => element["region"] == "Central");
  const eastern = postsData.filter((element) => element["region"] == "Eastern");
  const northern = postsData.filter(
    (element) => element["region"] == "Northern"
  );
  const western = postsData.filter((element) => element["region"] == "Western");

  const lengthAllRegions = [
    central.length,
    eastern.length,
    northern.length,
    western.length,
  ];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  // console.log(lengthAllRegions.reduce(reducer));
  const sumOfRegions = lengthAllRegions.reduce(reducer);

  // Convert count of @ region to Percentage
  const centralPercentage = addDecimals((central.length / sumOfRegions) * 100);
  const easternPercentage = addDecimals((eastern.length / sumOfRegions) * 100);
  const northernPercentage = addDecimals(
    (northern.length / sumOfRegions) * 100
  );
  const westernPercentage = addDecimals((western.length / sumOfRegions) * 100);
  // console.log(centralPercentage);

  // console.log(sizeof(found)/sizeof(found[0]));
  // console.log(found.length);

  // ASSESSMENT
  const mhTreatment = postsData.filter(
    (element) => element["accessed"] == "Seeking Mental Health Treatment"
  );
  const skPsychoSoc = postsData.filter(
    (element) => element["accessed"] == "Psychosocial Support"
  );
  const rehab = postsData.filter(
    (element) => element["accessed"] == "Rehabilitation"
  );
  const legal = postsData.filter(
    (element) => element["accessed"] == "Legal aid"
  );
  const safeProtection = postsData.filter(
    (element) => element["accessed"] == "Safety & Protection"
  );
  const livelihood = postsData.filter(
    (element) => element["accessed"] == "Livelihood/economic"
  );
  const schoolFees = postsData.filter(
    (element) => element["accessed"] == "School Fees"
  );
  const information = postsData.filter(
    (element) => element["accessed"] == "Seeking Information"
  );
  const peerSupport = postsData.filter(
    (element) => element["accessed"] == "Peer Support"
  );
  const tdrMedicine = postsData.filter(
    (element) =>
      element["accessed"] == "Traditional Medicine / Spiritual Healing"
  );
  const prayer = postsData.filter((element) => element["accessed"] == "Prayer");
  const others = postsData.filter((element) => element["accessed"] == "Others");

  const lengthAllAssessments = [
    mhTreatment.length,
    skPsychoSoc.length,
    rehab.length,
    legal.length,
    safeProtection.length,
    livelihood.length,
    schoolFees.length,
    information.length,
    peerSupport.length,
    tdrMedicine.length,
    prayer.length,
    others.length,
  ];
  // const reducer = (accumulator, currentValue) => accumulator + currentValue;
  // console.log(lengthAllRegions.reduce(reducer));
  const sumOfAssessments = lengthAllAssessments.reduce(reducer);

  // Convert count of @ region to Percentage
  const mhTreatmentPercentage = addDecimals(
    (mhTreatment.length / sumOfAssessments) * 100
  );
  const skPsychoSocPercentage = addDecimals(
    (skPsychoSoc.length / sumOfAssessments) * 100
  );
  const rehabPercentage = addDecimals((rehab.length / sumOfAssessments) * 100);
  const legalPercentage = addDecimals((legal.length / sumOfAssessments) * 100);
  const safeProtectionPercentage = addDecimals(
    (safeProtection.length / sumOfAssessments) * 100
  );
  const livelihoodPercentage = addDecimals(
    (livelihood.length / sumOfAssessments) * 100
  );
  const schoolFeesPercentage = addDecimals(
    (schoolFees.length / sumOfAssessments) * 100
  );
  const informationPercentage = addDecimals(
    (information.length / sumOfAssessments) * 100
  );
  const peerSupportPercentage = addDecimals(
    (peerSupport.length / sumOfAssessments) * 100
  );
  const tdrMedicinePercentage = addDecimals(
    (tdrMedicine.length / sumOfAssessments) * 100
  );
  const prayerPercentage = addDecimals(
    (prayer.length / sumOfAssessments) * 100
  );
  const othersPercentage = addDecimals(
    (others.length / sumOfAssessments) * 100
  );

  return isLoading ? (
    <Spinner
      animation="grow"
      variant="danger"
      style={{ marginTop: "70px", top: "50%", left: "50%", position: "fixed" }}
    />
  ) : (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-refresh-02 mr-1 text-info"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    {/* {posts.map((post, index) => ( */}
                    <div className="numbers">
                      <p className="card-category">Active Clients</p>
                      <Card.Title as="h4">{postsData.length}</Card.Title>
                    </div>
                    {/* ))} */}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <Button
                    size="sm"
                    className="btn-outline"
                    onClick={() =>
                      dispatch(
                        getPostsNoLimit(
                          user?.result?._id,
                          user?.result?.user_role
                        )
                      )
                    }
                  >
                    <i className="fas fa-redo mr-1"></i>
                    Update Now
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-success">
                        <AccessibilityIcon />
                      </i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Male</p>
                      <Card.Title as="h4">{males.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Total male clients
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger">
                        <PregnantWomanOutlinedIcon />
                      </i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Females</p>
                      <Card.Title as="h4">{females.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Total female clients
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-simple-remove text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Undisclosed</p>
                      <Card.Title as="h4">{undisclosed.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="nc-icon nc-explore-2 mr-3"></i>
                  Gender undisclosed
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Contact Center Stats</Card.Title>
                <p className="card-category">Calls by region</p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <div className="flag">
                              <i className="fas fa-circle mr-1 text-info"></i>
                            </div>
                          </td>
                          <td>CENTRAL</td>
                          <td className="text-right">{central.length}</td>
                          <td className="text-right">{centralPercentage}%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <i className="fas fa-circle mr-1 text-default"></i>
                            </div>
                          </td>
                          <td>EASTERN</td>
                          <td className="text-right">{eastern.length}</td>
                          <td className="text-right">{easternPercentage}%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <i className="fas fa-circle mr-1 text-danger"></i>
                            </div>
                          </td>
                          <td>NORTHERN</td>
                          <td className="text-right">{northern.length}</td>
                          <td className="text-right">{northernPercentage}%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <i className="fas fa-circle mr-1 text-success"></i>
                            </div>
                          </td>
                          <td>WESTERN</td>
                          <td className="text-right">{western.length}</td>
                          <td className="text-right">{westernPercentage}%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Case Category</Card.Title>
                <p className="card-category">
                  Based on Counselors' assessment while interacting with Clients
                </p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="5" className="mx-md-auto">
                    <ChartistGraph
                      className="ct-perfect-fourth"
                      data={{
                        labels: [
                          "mh",
                          "ps",
                          "rh",
                          "ls",
                          "sp",
                          "lv",
                          "If",
                          "pe",
                          "tm",
                          "pr",
                          "ot",
                        ],
                        series: [
                          mhTreatmentPercentage,
                          skPsychoSocPercentage,
                          rehabPercentage,
                          legalPercentage,
                          safeProtectionPercentage,
                          livelihoodPercentage,
                          schoolFeesPercentage,
                          informationPercentage,
                          peerSupportPercentage,
                          tdrMedicinePercentage,
                          prayerPercentage,
                          othersPercentage,
                        ],
                      }}
                      type="Pie"
                    />
                  </Col>
                  {/* Table */}
                  <Col md="9">
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#2EC3E6" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Mental Health Care (mh)</td>
                                <td className="text-right">
                                  {mhTreatment.length}
                                </td>
                                <td className="text-right">
                                  {mhTreatmentPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#EF434E" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Psychosocial Support (ps)</td>
                                <td className="text-right">
                                  {skPsychoSoc.length}
                                </td>
                                <td className="text-right">
                                  {skPsychoSocPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#FAA436" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Rehabilitation (rh)</td>
                                <td className="text-right">{rehab.length}</td>
                                <td className="text-right">
                                  {rehabPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#8069AE" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Legal Services (ls)</td>
                                <td className="text-right">{legal.length}</td>
                                <td className="text-right">
                                  {legalPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#88C540" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Safety & Protection (sp)</td>
                                <td className="text-right">
                                  {safeProtection.length}
                                </td>
                                <td className="text-right">
                                  {safeProtectionPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#5E5F5E" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>School Fees (sf)</td>
                                <td className="text-right">
                                  {schoolFees.length}
                                </td>
                                <td className="text-right">
                                  {schoolFeesPercentage}%
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col md="6">
                          <Table responsive>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#3575BB" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Livelihood (lv)</td>
                                <td className="text-right">
                                  {livelihood.length}
                                </td>
                                <td className="text-right">
                                  {livelihoodPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#5E5F5E" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Information (if)</td>
                                <td className="text-right">
                                  {information.length}
                                </td>
                                <td className="text-right">
                                  {informationPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#DD4B38" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Peer Support (pe)</td>
                                <td className="text-right">
                                  {peerSupport.length}
                                </td>
                                <td className="text-right">
                                  {peerSupportPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#36465C" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Traditional medicine (tm)</td>
                                <td className="text-right">
                                  {tdrMedicine.length}
                                </td>
                                <td className="text-right">
                                  {tdrMedicinePercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#E42E28" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Prayer (pr)</td>
                                <td className="text-right">{prayer.length}</td>
                                <td className="text-right">
                                  {prayerPercentage}%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <i
                                      className="fas fa-circle mr-1"
                                      style={{ color: "#5FA9DD" }}
                                    ></i>
                                  </div>
                                </td>
                                <td>Others</td>
                                <td className="text-right">{others.length}</td>
                                <td className="text-right">
                                  {othersPercentage}%
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Col>
                  {/* End Table */}
                </Row>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#2EC3E6" }}
                  ></i>
                  mh {mhTreatmentPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#EF434E" }}
                  ></i>
                  ps {skPsychoSocPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#FAA436" }}
                  ></i>
                  rh {rehabPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#8069AE" }}
                  ></i>
                  ls {legalPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#88C540" }}
                  ></i>
                  sp {safeProtectionPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#5E5F5E" }}
                  ></i>
                  sf {schoolFeesPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#3575BB" }}
                  ></i>
                  lv {livelihoodPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#5E5F5E" }}
                  ></i>
                  if {livelihoodPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#DD4B38" }}
                  ></i>
                  pe {peerSupportPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#36465C" }}
                  ></i>
                  tm {tdrMedicinePercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#E42E28" }}
                  ></i>
                  pr {prayerPercentage}% ||
                  <i
                    className="fas fa-circle mr-1"
                    style={{ color: "#5FA9DD" }}
                  ></i>
                  ot {othersPercentage}%
                </div>
                <hr></hr>
                <div className="stats">
                  <i
                    className="fas fa-redo mr-1"
                    onClick={() =>
                      dispatch(
                        getPostsNoLimit(
                          user?.result?._id,
                          user?.result?.user_role
                        )
                      )
                    }
                  ></i>
                  Refresh to fetch updated data!
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">Counselors</Card.Title>
                <p className="card-category">Performance</p>
              </Card.Header>
              <hr></hr>

              {/* Counselor Card */}
              {/* <Row className="d-flex justify-content-center"> */}
              <Row className="d-flex">
                {userStats?.map((user) => (
                  <Col
                    key={user?.userId}
                    lg="3"
                    sm="6"
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <Card className="card-stats">
                      <Card.Body>
                        <Row>
                          <Col xs="5">
                            <div className="icon-big text-center icon-warning">
                              {/* <i className="nc-icon nc-refresh-02 mr-1 text-info"></i> */}
                              <i className="fas fa-headset"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            {/* {posts.map((post, index) => ( */}
                            <div className="numbers">
                              <Card.Title as="h4">{user?.name}</Card.Title>
                              <p
                                className="card-category"
                                style={{ color: "#591C21" }}
                              >
                                Clients || {user?.posts}
                              </p>
                            </div>
                            {/* ))} */}
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <div className="stats">
                          <Button
                            size="sm"
                            className="btn"
                            // onClick={() =>
                            //   dispatch(
                            //     getPostsNoLimit(
                            //       user?.result?._id,
                            //       user?.result?.user_role
                            //     )
                            //   )
                            // }
                          >
                            {/* <i className="fas fa-redo mr-1"></i> */}
                            <i className="fas fa-user-tag mr-1"></i>
                            Sessions ~ {user?.sessions}
                          </Button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
              {/* Counselor Card */}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
